<!--
 * @Description: 
 * @Version: 
 * @Autor: yqf
 * @Date: 2022-07-20 15:11:06
 * @LastEditors: yqf
 * @LastEditTime: 2022-07-20 15:13:26
 * @FilePath: \yxj-web\src\components\Foot.vue
-->
<template>
  <div class="foot">
    <div class="foot-area">
      <div class="copyright">
        <span class="first">©2010 ~ 2021 医学界</span><br />
        <a
          target="_bank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          >沪ICP备 12014230号-1;</a
        ><br v-if="$store.getters.mobile" />
        <a
          target="_bank"
          href="https://studioyszimg.yxj.org.cn/1600401087999.jpg"
          >信息系统安全保护等级备案证明 31011013186-18001;</a
        ><br v-if="$store.getters.mobile" />
        <a
          target="_bank"
          href="https://studioyszimg.yxj.org.cn/1600401049015.jpg"
          >增值电信业务经营许可证 <span class="last">沪B2-20170033</span></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Foot',
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./foot.scss";
</style>
